import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import _ from 'lodash';

import {
  CATEGORY_SEARCH_PARAM,
  SORT_SEARCH_PARAM,
  FILTER_SEARCH_PARAMS,
} from '../../config/routes';

import {
  getCategories,
  getRewards,
  getConfigurations,
  getGiftCardsBrandsByName,
} from '../../utils/service';
import { getGenericError } from '../../utils/errors';
import { getSearchParam } from '../../utils/routes';
import {
  FLAGS,
  getCategory,
  getFilter,
  getFilterParams,
  getCategoryParam,
  updateQueryFilters,
  getSearchTextParam,
} from '../Rewards/utils';
import { REWARD_TYPE } from '../../config/rewards';

import { useBreakpoint } from '../../../../hooks/useBreakPoint';
import ModalWrapper from '../../global/ModalWrapper';
import RedeemModal from '../Reward/RedeemModal';

import {
  getSort,
  getSortingParam,
} from '../../utils/sorting';

import SEARCH_TYPE from '../../utils/searchType';
import searchOptionsModal from '../../utils/search';

import './SearchBar.scss';

import RewardsSearchInput from './RewardsSearchInput';
import RewardsSearch from './RewardsSearch';

const LIST_ELEMENT_ID = 'ModalList';
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;

const SearchBar = ({
  onOpenChange,
  searchType = SEARCH_TYPE.DEALS,
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const size = useBreakpoint();
  const timeoutRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsTotal, setSearchResultsTotal] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isLoadingRewards, setIsLoadingRewards] = useState(true);
  const [rewardsError, setRewardsError] = useState('');
  const [filterSelected, setFilterSelected] = useState({});
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [searchedText, setSearchedText] = useState('');
  const [redeemRewardItem, setRedeemRewardItem] = useState(null);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);

  const defaulSortingOptions = _.first(searchOptionsModal[searchType].sorting);
  const [sortingSelected, setSortingSelected] = useState(
    _.first(defaulSortingOptions),
  );

  const query = useMemo(() => (
    new URLSearchParams(search)
  ), [search]);

  const {
    rewardSortParam,
    rewardFilterTypeParam,
    rewardFilterMinValueParam,
    rewardFilterMaxValueParam,
    rewardFilterIsNewParam,
  } = useMemo(() => ({
    rewardSortParam: getSearchParam(query, SORT_SEARCH_PARAM),
    rewardFilterMinValueParam: getSearchParam(query, FILTER_SEARCH_PARAMS.MIN_VALUE),
    rewardFilterMaxValueParam: getSearchParam(query, FILTER_SEARCH_PARAMS.MAX_VALUE),
    rewardFilterTypeParam: getSearchParam(query, FILTER_SEARCH_PARAMS.TYPE),
    rewardFilterIsNewParam: getSearchParam(query, FILTER_SEARCH_PARAMS.IS_NEW),
  }), [query]);

  const loadCategoryRewards = useCallback(async (
    routeName,
    sorting,
    filter,
    newCategories,
    searchText,
  ) => {
    setIsLoadingRewards(true);

    if (!searchText) {
      setIsLoadingRewards(false);
      setSearchResults([]);
      return;
    }

    if (searchType === SEARCH_TYPE.GIFT_CARDS) {
      const {
        data,
        error: giftCardsBrandsError,
      } = await getGiftCardsBrandsByName(searchText);

      if (giftCardsBrandsError) {
        setRewardsError(giftCardsBrandsError.message || getGenericError());
        setIsLoadingRewards(false);
        return;
      }

      setSearchResults([...data]);
      setSearchResultsTotal(data.length);
    } else {
      const category = getCategory(newCategories?.length ?
        newCategories : categories, routeName);
      const updatedFilter = { ...filter };

      if (searchType === SEARCH_TYPE.REWARDS && !Object.prototype.hasOwnProperty.call(filter, 'minValue')) {
        updatedFilter.minValue = 1;
      }

      const filterDeals = searchOptionsModal[searchType].filter;
      const {
        data,
        error: categoryRewardsError,
      } = await getRewards({
        page: DEFAULT_PAGE,
        pageSize: DEFAULT_PAGE_SIZE,
        ...getCategoryParam(category.uid),
        ...getSortingParam(sorting),
        ...getFilterParams(updatedFilter),
        ...filterDeals,
        ...getSearchTextParam(searchText),
      });

      if (categoryRewardsError) {
        setRewardsError(categoryRewardsError.message || getGenericError());
        setIsLoadingRewards(false);
        return;
      }

      setSearchResults([...data.rewards]);
      setSearchResultsTotal(data.total);
    }
    setRewardsError('');
    setIsLoadingRewards(false);
  }, [
    selectedCategory,
    sortingSelected,
    filterSelected,
    categories,
    searchResultsTotal,
    searchResults,
  ]);

  const loadCategories = useCallback(async () => {
    const {
      data,
      error: rewardCategoriesError,
    } = await getCategories({ maxExchangeValue: 0 });

    if (rewardCategoriesError) {
      setCategories([]);
      setSelectedCategory('');
      return;
    }

    const newCategories = [
      ...searchOptionsModal[searchType].categories,
      ...data.categories,
    ];
    const initialSelectedCategory = getCategory(newCategories, FLAGS.ALL);
    const initialSort = getSort(rewardSortParam);
    const initialFilter = getFilter({
      type: rewardFilterTypeParam,
      minValue: rewardFilterMinValueParam,
      maxValue: rewardFilterMaxValueParam,
      other: {
        [FLAGS.IS_NEW]: rewardFilterIsNewParam,
      },
    });

    setCategories(newCategories);
    setSelectedCategory(initialSelectedCategory.routeName);
    setSortingSelected(initialSort);
    setFilterSelected(initialFilter);
    loadCategoryRewards(
      initialSelectedCategory.routeName,
      initialSort,
      initialFilter,
      newCategories,
    );

    query.set(CATEGORY_SEARCH_PARAM, initialSelectedCategory.routeName);
    query.set(SORT_SEARCH_PARAM, initialSort.id);
    updateQueryFilters(query, initialFilter);
    const { pathName } = searchOptionsModal[searchType];
    history.push({
      pathname: pathName,
      search: query.toString(),
    });
  }, [
    rewardSortParam,
    rewardFilterTypeParam,
    rewardFilterMinValueParam,
    rewardFilterMaxValueParam,
    rewardFilterIsNewParam,
    loadCategoryRewards,
  ]);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  useEffect(() => {
    loadCategories();
    rewardsConfig();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const handleOnClick = useCallback((rewardItem, redeem = false) => {
    const { type } = rewardItem;
    if (openRedeemModal) {
      return;
    }

    if (searchType === SEARCH_TYPE.DEALS
      && redeem
      && type !== REWARD_TYPE.SEGMENTED_BY_PURCHASED_SKU) {
      setRedeemRewardItem(rewardItem);
      setOpenRedeemModal(true);
      setIsOpen(false);
      return;
    }

    const objectIds = {
      [SEARCH_TYPE.DEALS]: { store: rewardItem.store?.id, itemId: rewardItem.uid },
      [SEARCH_TYPE.REWARDS]: { store: rewardItem.store?.id, itemId: rewardItem.uid },
      [SEARCH_TYPE.GIFT_CARDS]: { brandId: rewardItem.uid },
    };

    const clickedItem = objectIds[searchType];

    const relativePath = searchOptionsModal[searchType].getRelativePath(clickedItem);

    history.push(relativePath);
  }, [openRedeemModal, history, setRedeemRewardItem, setOpenRedeemModal]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);

    if (onOpenChange) {
      onOpenChange(true);
    }
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setSearchedText('');

    if (onOpenChange) {
      onOpenChange(false);
    }
  }, []);

  const clearFiltersAndResults = () => {
    setFilterSelected({});
    setSearchResults([]);
  };

  return (
    <>
      <div className="SearchBar">
        <ModalWrapper
          props={{
            open: isOpen,
            maxWidth: size,
            onClose: handleClose,
          }}
        >
          <Box className="SearchBar__modal">
            <RewardsSearch
              searchType={searchType}
              loadCategoryRewards={loadCategoryRewards}
              handleOnClick={handleOnClick}
              rewardProperties={rewardProperties}
              isLoadingRewards={isLoadingRewards}
              isLoadingConfig={isLoadingConfig}
              categoryRewards={searchResults}
              categoryRewardsTotal={searchResultsTotal}
              selectedCategory={selectedCategory}
              sortingSelected={sortingSelected}
              filterSelected={filterSelected}
              rewardsError={rewardsError}
              listElementId={LIST_ELEMENT_ID}
              setSearchedText={setSearchedText}
              searchedText={searchedText}
              onClose={handleClose}
              onClear={clearFiltersAndResults}
            />
          </Box>
        </ModalWrapper>
        <RewardsSearchInput
          setIsSearching={handleOpen}
          clearFiltersAndResults={clearFiltersAndResults}
          searchType={searchType}
        />
      </div>
      <RedeemModal
        rewardItem={redeemRewardItem}
        showModal={openRedeemModal}
        onClose={() => setOpenRedeemModal(false)}
      />
    </>
  );
};

export default SearchBar;
