import classNames from 'classnames';
import RewardItem from '../RewardItem';

import './RewardsList.scss';

const RewardsList = ({ rewardsList, onClick, rewardProperties, className }) => (
  <div className={classNames('RewardsViewList', className)}>
    {rewardsList.map((rewardItem) => (
      <RewardItem
        key={`reward-${rewardItem.uid}`}
        rewardItem={rewardItem}
        onClick={onClick}
        rewardProperties={rewardProperties}
      />
    ))}
  </div>
);

export default RewardsList;
