import { useCallback, useMemo } from 'react';
import { usePostHog } from 'posthog-js/react';
import classNames from 'classnames';

import { Flag } from '../../../global/Rewards';
import {
  getActionType,
  getEventName,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
} from '../../../config/tracker';
import { REWARD_AMOUNT_TYPES } from '../../../config/rewards';
import { isDonationType, isLimitErrorCode, isUserLimitErrorCode } from '../../../utils/rewards';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import TCCoinSvg from '../../../global/SvgComponents/TCCoin_svg';
import ChevronUpSvg from '../../../global/SvgComponents/ChevronUp_svg';
import IconGemCircle from '../../../images/icon-gem-circle.svg';

import './RewardItem.scss';

const {
  rewards: {
    list: {
      rewardItem: trackerRewardItem,
      claimButton: trackerClaimButton,
    },
  },
} = TRACKER_IDS;

const getCtaLabel = ({ isFreeReward, exchangeValue, type, disabled }) => {
  if (isFreeReward) {
    return disabled ? 'Deal details' : 'Claim Deal';
  }

  const isDonation = isDonationType(type);

  if (disabled) {
    return `${isDonation ? 'Donation' : 'Reward'} details`;
  }

  return `${isDonation ? 'Donate' : 'Get it for'} ${exchangeValue}`;
};

const RewardItem = ({
  rewardItem,
  onClick,
  rewardProperties,
}) => {
  const { isSignedIn, user } = useFirebaseAuth();
  const posthog = usePostHog();

  const {
    uid: rewardId,
    name: rewardName,
    description: rewardDescription,
    amountType,
    rewardAmount,
    type,
    exchangeValue,
    status,
    isNew,
    campaignEndDate,
    rewardCodes,
    allowTcCredits,
    store: {
      logoUrl,
      imgUrl,
      RewardLogoProperties,
      tcCreditAmount,
    },
  } = useMemo(() => rewardItem, [rewardItem]);

  const isFreeReward = useMemo(() => Number(exchangeValue) === 0, [exchangeValue]);

  const disabled = useMemo(() => (
    isLimitErrorCode(status) || isUserLimitErrorCode(status)
  ), [status]);

  const amountText = useMemo(() => (
    REWARD_AMOUNT_TYPES[amountType]?.getText({ rewardAmount, type }) ?? ''
  ), [amountType, rewardAmount]);

  const handleOnClick = useCallback((fromCta = false) => {
    const claim = fromCta && isFreeReward && !disabled;

    posthog.capture(
      getEventName(claim ? trackerClaimButton : trackerRewardItem),
      {
        [POSTHOG_PROPERTIES.REWARD_ID]: rewardId,
        [POSTHOG_PROPERTIES.REWARD_NAME]: rewardName,
        ...(isSignedIn && user && ({
          $set: { email: user.email },
        })),
      },
    );

    onClick?.(
      rewardItem,
      fromCta && !disabled,
    );
  }, [rewardId, rewardName, onClick, isSignedIn, user, disabled, rewardItem]);

  return (
    <div className={classNames('RewardsViewItem', { disabled })}>
      {!isFreeReward && (
        <img
          className="RewardsViewItem__gem"
          src={IconGemCircle}
          alt="reward item"
        />
      )}
      <Flag
        status={status}
        isNew={isNew}
        isFreeReward={isFreeReward}
        hasRewardCodes={rewardCodes.length > 0}
        exchangeValue={exchangeValue}
        expireAt={campaignEndDate}
        allowTcCredits={allowTcCredits}
        tcCreditAmount={tcCreditAmount}
        properties={rewardProperties}
      />
      <button
        className={classNames('RewardsViewItem__caret', { disabled })}
        id={`RewardsViewItem__caret-${rewardItem.uid}`}
        onClick={() => handleOnClick()}
        type="button"
        aria-label="reward item caret"
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleOnClick();
          }
        }}
        {...{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerRewardItem),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerRewardItem),
          [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: rewardId,
          [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: rewardName,
        }}
      >
        <div
          className="RewardsViewItem__caret--cover"
          alt="store image"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${imgUrl})`,
          }}
        >
          <img
            className="RewardsViewItem__caret--cover-logo"
            src={logoUrl}
            style={{
              ...RewardLogoProperties,
              ...(RewardLogoProperties?.height ? { maxHeight: RewardLogoProperties.height, height: 'unset' } : {}),
            }}
            alt="store logo"
          />
        </div>
        <div className="RewardsViewItem__caret--content">
          <div className="RewardsViewItem__caret--content-text">
            <p className="RewardsViewItem__caret--content-text-title" title={amountText}>{amountText}</p>
            <p className="RewardsViewItem__caret--content-text-subtitle" title={rewardDescription}>{rewardDescription}</p>
          </div>
          <div className="RewardsViewItem__caret--content-arrow">
            <ChevronUpSvg
              color="var(--trashie-ui-faded-black-80)"
              width={12}
            />
          </div>
        </div>
      </button>
      <button
        type="button"
        className="RewardsViewItem__cta"
        id={`RewardsViewItem__cta-${rewardItem.uid}`}
        onClick={() => handleOnClick(true)}
        aria-label="reward item action"
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleOnClick(true);
          }
        }}
        {...{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(
            isFreeReward && !disabled ? trackerClaimButton : trackerRewardItem,
          ),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(
            isFreeReward && !disabled ? trackerClaimButton : trackerRewardItem,
          ),
          [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: rewardId,
          [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: rewardName,
        }}
      >
        <span className="RewardsViewItem__cta--label">
          {getCtaLabel({ isFreeReward, exchangeValue, type, disabled })}
        </span>
        {!isFreeReward && !disabled && (
          <TCCoinSvg width="12" height="12" />
        )}
      </button>
    </div>
  );
};

export default RewardItem;
